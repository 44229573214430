import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Home';
import NavbarComponent from './components/NavBar/NavbarComponent';
function App() {
  return (
    <div className="App">
              <NavbarComponent/>
        <Home/>
    </div>
  );
}
export default App;