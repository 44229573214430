import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import '../../App.css';
import { useNavigate } from "react-router-dom";
import data from '../../utils/data.json';
import { useRef } from "react";


function Home() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/contact`;
    navigate(path);
  }
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div className="App">
      <div className="App-section">
        <header className="App-header">
          {data.Home.header}
        </header>
        <header2 className="App-subheader">
          {data.Home.subheader}
        </header2>
        <Stack>
          <Col md>
            <Button className='button2' onClick={executeScroll}
            >{data.Home.button2}</Button>
          </Col>
          <Col md>
            <Button className='button' onClick={routeChange}
            >{data.Home.button}</Button>
          </Col>
        </Stack>
      </div>
      <div ref={myRef} className='App-subsection'>
        <header className="App-subheader-2">
          {data.Home.header2}
          <br />
          <br />
          {data.Home.subheader2}
        </header>
      </div>
    </div>
  );
}
export default Home;