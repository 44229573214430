import NavbarComponent from '../NavBar/NavbarComponent';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import data from '../../utils/data.json';


function Contact() {
  const form = useRef();
  const [showForm, setShowForm] = useState(true);
  const [subheader, setSubheader] = useState(data.Contact.subheader);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xknaffn', 'template_y6uj56r', form.current, 'E3GMAxeRN_RclviaA')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    setShowForm(false);
    setSubheader(data.Contact.submission);
  };

  const EmailForm = () => (
    <form ref={form} onSubmit={sendEmail}>
      <Stack className="contact-form">
        <div className="contact-form-item">
          <div class="input-group mb-3">
            <span class="input-group-text">Name</span>
            <input type="text" aria-label="Name" class="form-control" placeholder="Enter your full name" name="user_name" />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">Email</span>
            <input type="text" aria-label="Email" class="form-control" placeholder="Enter your email address" name="user_email" />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text">Message</span>
            <textarea aria-label="Message" class="form-control" name="message" />
          </div>
        </div>
        <Button className="button" type="submit" onClick={sendEmail}>{data.Contact.button}</Button>
      </Stack>
    </form>
  )
  return (
    <div className="App">
      <NavbarComponent />
      <div className="App-section">
        <header className="App-header">
          {data.Contact.header}
        </header>
        <label className="contact-form-subheader">
          {subheader}
        </label>
        {showForm ? <EmailForm /> : null}
      </div>
    </div>
  );
}
export default Contact;
